<template>
  <div>
    <Content style="padding: 16px">
      <canvas ref="chart"></canvas>
    </Content>

    <div class="small-legends">
      <div class="legend" v-for="legend in legends" :key="legend">
        <div class="bloc" :style="{ background: legend.color }"></div>
        <span>{{ legend.label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Content from "@/views/App/Transmission/Components/Content";
import { formatAmount } from "@/utils/number";
import _ from "lodash";

const getChartConfig = (data) => {
  // Chart config
  return {
    type: "bar",
    data: data,
    options: {
      font: {
        size: 24,
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || "";
              if (context.parsed.y !== null) {
                label = formatAmount(context.parsed.y, { cents: false });
              }
              return label;
            },
          },
        },
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          stacked: true,

          display: true,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            font: {
              family: "Weissenhof Grotesk",
              size: 13,
            },
            color: "#B5B5C3",
          },
        },
        y: {
          stacked: true,

          display: true,
          beginAtZero: true,

          ticks: {
            font: {
              family: "Weissenhof Grotesk",
              size: 10,
            },
            color: "#B5B5C3",
            callback: function (label) {
              return formatAmount(label, { cents: false });
            },
          },
          grid: {
            drawBorder: false,
            lineWidth: 3,
            color: (context) => {
              const zeroLine = context.tick.value;
              const gridColor = zeroLine === 0 ? "#CFD2D4" : "transparent";
              return gridColor;
            },
          },
          //type: "logarithmic",
        },
      },
    },
    defaults: {
      global: {},
    },
  };
};

export default {
  name: "ChartProfitByStrategy",
  components: { Content },
  props: ["data"],
  data() {
    return {
      legends: [],
    };
  },
  mounted() {
    this.drawChart();
  },
  methods: {
    drawChart() {
      //const colors = _.map(this.data, () => "#AC2369");

      const colors = [
        "#AC2369",
        "rgba(113, 200, 218, 1)",
        "rgba(206, 215, 239, 1)",
        "rgba(145, 155, 192, 1)",

        "rgba(0, 55, 112, 1)",

        "#DA9EB5",
        "#CD7498",
        "#E9B9CA",
        "#C35C88",
        "#F0D2DD",
        "#B84378",
        "#D78CA9",
        "#F8E8ED",
        "#E0A3B9",
        "rgba(33, 36, 78, 1)",
      ];

      const d = _.map(this.data.datasets, (item) => {
        return _.map(item.data);
      });

      let totalAmountByYear = [];
      if (d.length > 0) {
        for (let i = 0; i < d[0].length; i++) {
          totalAmountByYear[i] = d[0][i];
          for (let j = 1; j < d.length; j++) {
            totalAmountByYear[i] += d[j][i];
          }
        }
      }

      const predicateTotalAmountToZero = (value, index) =>
        totalAmountByYear[index] !== 0;

      const labelsWithoutYearToZero = _.filter(
        this.data.labels,
        predicateTotalAmountToZero
      );

      const data = {
        labels: labelsWithoutYearToZero,
        datasets: _.map(this.data.datasets, (item, index) => {
          return {
            ...item,
            data: _.filter(item.data, predicateTotalAmountToZero),
            backgroundColor: _.get(colors, index),
            borderRadius: 2,
          };
        }),
      };

      this.legends = _.map(this.data.datasets, (item, index) => {
        return {
          label: item.label,
          color: _.get(colors, index),
        };
      });

      // Chart config
      const config = getChartConfig(data);
      const ctx = this.$refs.chart;

      this._drawChart(ctx, config);
    },
    _drawChart(ctx, config) {
      this.chartInstance?.destroy();
      // eslint-disable-next-line no-undef,no-unused-vars
      this.chartInstance = new Chart(ctx, config);
    },
  },
};
</script>

<style scoped>
.small-legends {
  margin-top: 12px;
}
.small-legends :last-child {
  margin-right: 0px !important;
}

.small-legends .legend {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  margin-right: 12px;
}
.small-legends .legend .bloc {
  width: 8px;
  height: 8px;
  border-radius: 1px;
}
</style>
