<template>
  <div class="aligner" style="min-height: calc(100vh - 250px)">
    <Subtitle1 color="gray-2"
      >Cette section est en cours de développement.</Subtitle1
    >
  </div>
</template>

<script>
import Subtitle1 from "@/components/Texts/Subtitle1";

export default {
  name: "TabSante",
  components: {
    Subtitle1,
  },
};
</script>

<style scoped>
.container-grid {
  grid-gap: 16px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  display: grid;
  margin-bottom: 16px;
}

.container-grid > div {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: fit-content;
}

.container-grid > div > * {
  position: relative;
  padding: 16px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 16px;
  grid-template-rows: auto minmax(0, 1fr);
}
/*
box-shadow: 0px 2px 12px rgba(33, 36, 78, 0.1);
border-radius: 8px;
 */

/* Mobile only */
@media only screen and (pointer: coarse) and (min-device-width: 320px) and (max-device-width: 480px) {
  .container-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
</style>
