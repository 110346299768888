<template>
  <div class="aligner" style="min-height: calc(100vh - 250px)" v-if="noData">
    <Subtitle1 color="gray-2"
      >Votre tableau de bord est en cours de création</Subtitle1
    >
  </div>

  <a-alert
    style="margin-bottom: 10px"
    :message="reportingDateMessage"
    type="info"
    show-icon
    v-if="!noData"
  >
    <template #icon>
      <Icon
        style="width: 20px"
        color="rgba(45, 49, 109, 1)"
        size="thin"
        name="info"
      />
    </template>
  </a-alert>

  <div class="container-grid" v-if="!noData">
    <div>
      <Card>
        <Heading6>Gains par stratégie</Heading6>
        <ChartProfitByStrategy
          v-if="dataProfitByStrategy !== null"
          :data="dataProfitByStrategy"
        />
      </Card>
      <Card>
        <Heading6>Répartition par stratégie</Heading6>
        <ChartBreakdownByStrategy
          v-if="dataBreakdownByStrategy !== null"
          :data="dataBreakdownByStrategy"
        />
      </Card>
    </div>
    <div>
      <Card>
        <Heading6>Gains calendaires</Heading6>
        <ChartProfitByDate
          v-if="dataProfitByDate !== null"
          :data="dataProfitByDate"
        />
      </Card>
      <Card>
        <Heading6>Analyse PCRL cible vs actuel</Heading6>
        <ChartPCRL v-if="dataPCRL !== null" :data="dataPCRL"></ChartPCRL>
      </Card>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

import Card from "@/components/Cards/Card";
import Heading6 from "@/components/Texts/Heading6";
import ChartPCRL from "@/views/App/KermonyServices/Dashboard/PCRL/ChartPCRL";
import _ from "lodash";
import ChartBreakdownByStrategy from "@/views/App/KermonyServices/Dashboard/BreakdownByStrategy/ChartBreakdownByStrategy";
import ChartProfitByDate from "@/views/App/KermonyServices/Dashboard/ProfitByDate/ChartProfitByDate";
import ChartProfitByStrategy from "@/views/App/KermonyServices/Dashboard/ProfitByStrategy/ChartProfitByStrategy";
import ReportingApi from "@/api/reporting";
import { ref } from "vue";
import Subtitle1 from "@/components/Texts/Subtitle1";
import Icon from "@/components/Icons/Icon";

const parseBreakdownByStrategy = (data) => {
  data.pop();
  return data;
};

const profitByStrategy = (data) => {
  const labels = [..._.map(_.first(data).values, ({ year }) => year), "Total"];
  const datasets = _.map(data, (item) => {
    const data = _.map(item.values, ({ value }) => value);
    const total = _.sum(data);
    return {
      label: item.name,
      data: [...data, total],
    };
  });

  return {
    labels,
    datasets,
  };
};

const parseProfitByDate = (data) => {
  // eslint-disable-next-line no-unused-vars
  const [first, ...rest] = data;
  // this.dataProfitByDate = [...rest, first];
  const total = _.sumBy(rest, ({ value }) => value);
  return [...rest, { name: "Total", value: total }];
};

const parseDataPCRL = (data) => {
  let dataCible = null;
  let dataActuel = null;

  _.each(data, (item) => {
    switch (item.type) {
      case "actuel":
        dataActuel = item.values;
        break;
      case "cible":
        dataCible = item.values;
        break;
    }
  });

  // check data

  if (dataCible.length !== dataActuel.length) {
    console.warn("PCRL Data unexpected format");
    return;
  }

  let legends = [];

  for (let i = 0; i < dataCible.length; i++) {
    if (dataCible[i].name !== dataActuel[i].name) {
      console.warn("PCRL Data unexpected format");
      return;
    }

    legends.push(dataCible[i].name);
  }

  return {
    legends,
    target: _.map(dataCible, ({ value }) => value),
    current: _.map(dataActuel, ({ value }) => value),
  };
};

export default {
  name: "TabDashboard",
  components: {
    Icon,
    Subtitle1,
    ChartProfitByStrategy,
    ChartProfitByDate,
    ChartBreakdownByStrategy,
    ChartPCRL,
    Heading6,
    Card,
  },
  async setup() {
    const lastReportingData = ref(null);
    const dataPCRL = ref(null);
    const dataBreakdownByStrategy = ref(null);
    const dataProfitByDate = ref(null);
    const dataProfitByStrategy = ref(null);
    const noData = ref(null);
    let reportingDateMessage = ref(null);

    try {
      lastReportingData.value = await ReportingApi.fetchLastReportingData();
      reportingDateMessage = `Données au ${moment(lastReportingData.value.date).format("DD/MM/YYYY")} en euros nets de frais. Source : Harvest, Kermony Office. Les performances passées ne présagent pas des résultats futurs. L’analyse PCRL résulte de la méthode propriétaire de Kermony Office afin de représenter votre allocation d’actifs selon les dimensions de préservation, croissance, rendement et liquidité. Il est important de ne pas se fier exclusivement à ces informations pour prendre des décisions d’investissement ou pour évaluer la rentabilité d’un portefeuille. Il est recommandé de consulter votre family officer chez Kermony avant de prendre des décisions d’investissement.`
    } catch (e) {
      noData.value = true;
      return {
        lastReportingData,
        dataPCRL,
        dataBreakdownByStrategy,
        dataProfitByDate,
        dataProfitByStrategy,
        noData,
        reportingDateMessage,
      };
    }

    // Analyse PCRL cible vs actuel
    dataPCRL.value = parseDataPCRL(
      _.get(lastReportingData.value, "data.data1", null)
    );

    // Répartition par stratégie
    dataBreakdownByStrategy.value = parseBreakdownByStrategy(
      _.get(lastReportingData.value, "data.data3", null)
    );

    // Gains calendaires
    dataProfitByDate.value = parseProfitByDate(
      _.get(lastReportingData.value, "data.data2", null)
    );

    // Gains par stratégie
    dataProfitByStrategy.value = profitByStrategy(
      _.get(lastReportingData.value, "data.data4", null)
    );

    return {
      lastReportingData,
      dataPCRL,
      dataBreakdownByStrategy,
      dataProfitByDate,
      dataProfitByStrategy,
      noData,
      reportingDateMessage
    };
  },
};
</script>

<style scoped>
.container-grid {
  grid-gap: 16px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  display: grid;
  margin-bottom: 16px;
}

.container-grid > div {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: fit-content;
}

.container-grid > div > * {
  position: relative;
  padding: 16px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 16px;
  grid-template-rows: auto minmax(0, 1fr);
}
/*
box-shadow: 0px 2px 12px rgba(33, 36, 78, 0.1);
border-radius: 8px;
 */

/* Mobile only */
@media only screen and (pointer: coarse) and (min-device-width: 320px) and (max-device-width: 480px) {
  .container-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
</style>
