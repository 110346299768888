<template>
  <WorkspaceTabs noPadding>
    <a-tabs animated>
      <a-tab-pane key="1" tab="Tableau de bord">
        <Suspense>
          <!-- main content -->
          <TabDashboard />

          <!-- loading state -->
          <template #fallback
            ><div class="aligner" style="min-height: calc(100vh - 250px)">
              <div>
                <div class="aligner">
                  <Loader></Loader>
                </div>
                <div style="text-align: center">
                  <Heading4 style="color: var(--kermony-office-violet)"
                    >Chargement en cours
                  </Heading4>
                </div>
              </div>
            </div></template
          >
        </Suspense>
      </a-tab-pane>
      <a-tab-pane key="2" tab="Les analyses Kermony">
        <TableAnalyse :reportingFiles="reportingFiles" />
      </a-tab-pane>
      <a-tab-pane key="3" tab="Indicateurs de santé patrimoniale">
        <TabSante />
      </a-tab-pane>
    </a-tabs>
  </WorkspaceTabs>
</template>

<script>
import ReportingApi from "@/api/reporting";
import WorkspaceTabs from "@/components/Workspaces/WorkspaceTabs";
import TableAnalyse from "@/views/App/KermonyServices/Analyse/TableAnalyse";
import TabDashboard from "@/views/App/KermonyServices/Dashboard/TabDashboard";
import TabSante from "@/views/App/KermonyServices/Sante/Tab";
import Loader from "@/components/Loaders/Loader";
import Heading4 from "@/components/Texts/Heading4.vue";

export default {
  name: "Dashboard",
  components: { Heading4, Loader, TabDashboard, TableAnalyse, TabSante, WorkspaceTabs },
  mounted() {
    this.$store.commit("updateHeader", {
      title: "Kermony & Moi",
      subtitle: "Unis, nous sommes plus forts",
    });
    this.loadData();
  },
  data() {
    return {
      reportingFiles: null,
      lastReportingData: null,
    };
  },
  methods: {
    async loadData() {
      this.reportingFiles = await ReportingApi.fetchReportingFiles();
    },
  },
};
</script>
