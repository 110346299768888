<template>
  <div>
    <!--
    padding 16 on this page but padding 8 16 on "ma transmission"
    -->
    <Content>
      <ChartContainer :legends="legends" :legendsScrollable="true">
        <canvas ref="chart"></canvas>
      </ChartContainer>
    </Content>
  </div>
</template>

<script>
import ChartContainer from "@/views/App/Transmission/Components/ChartContainer";
import Content from "@/views/App/Transmission/Components/Content";
import { getChartConfig, toLegends } from "@/views/App/Transmission/ChartUtils";
import _ from "lodash";
import { formatAmount } from "@/utils/number";

const convertValuesToPercentage = (data) => {
  const total = _.sumBy(data, ({ value }) => value);

  return _.map(data, (item) => ({
    ...item,
    value: `${((item.value / total) * 100).toFixed(0)}%`,
  }));
};

export default {
  name: "ChartBreakdownByStrategy",
  components: { ChartContainer, Content },
  props: ["data"],
  data() {
    return {
      legends: [],
    };
  },
  mounted() {
    this.drawChart();
  },
  methods: {
    drawChart() {
      //const colors = ["#71C8DA", "#AC2369", "#003770", "#919BC0"];
      const colors = [
        "rgba(33, 36, 78, 1)",
        "rgba(0, 55, 112, 1)",
        "rgba(113, 200, 218, 1)",
        "rgba(145, 155, 192, 1)",
        "rgba(206, 215, 239, 1)",
        "#DA9EB5",
        "#CD7498",
        "#E9B9CA",
        "#C35C88",
        "#F0D2DD",
        "#B84378",
        "#D78CA9",
        "#F8E8ED",
        "#E0A3B9",
        "#AC2369",
      ];

      const data = {
        labels: _.map(
          convertValuesToPercentage(this.data),
          ({ name, value }) => [name, value]
        ),
        datasets: [
          {
            data: _.map(this.data, ({ value }) => value),
            backgroundColor: colors,
          },
        ],
      };

      this.legends = toLegends(data.labels, colors);

      // Chart config
      const config = getChartConfig(data);

      _.set(config, "options.plugins.tooltip.callbacks", {
        label: function (context) {
          const [head, ...rest] = context.label;
          return [
            head,
            formatAmount(context.parsed, { cents: false }),
            ...rest,
          ];
        },
      });
      const ctx = this.$refs.chart;

      this._drawChart(ctx, config);
    },
    _drawChart(ctx, config) {
      this.chartInstance?.destroy();
      // eslint-disable-next-line no-undef,no-unused-vars
      this.chartInstance = new Chart(ctx, config);
    },
  },
};
</script>

<style scoped></style>
