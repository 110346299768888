import HTTP from "@/axios";

export default {
  fetchReportingFiles() {
    return new Promise((resolve, reject) => {
      HTTP.get("reporting/files")
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchLastReportingData() {
    return new Promise((resolve, reject) => {
      HTTP.post("reporting/fetch-last-reporting-data")
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
