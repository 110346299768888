<template>
  <span class="heading4"><slot></slot></span>
</template>

<script>
export default {
  name: "Heading4",
};
</script>

<style scoped>
.heading4 {
  font-family: "Weissenhof Grotesk";
  font-style: normal;
  font-weight: 280;
  font-size: 36px;
  line-height: 47px;
  letter-spacing: 0.25px;
  color: var(--kermony-office-bleu-1);
}
</style>
