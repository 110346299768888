<template>
  <TableFiles>
    <RowFile
      v-for="file in reportingFile.files"
      :key="file.name"
      :name="file.displayName"
      :size="file.size"
      :date="file.date"
      :onDownload="() => downloadFile(reportingFile.name, file.name)"
    ></RowFile>
  </TableFiles>
</template>

<script>
import TableFiles from "@/components/Tables/TableFiles";
import RowFile from "@/components/Tables/TableFiles/RowFile";
export default {
  name: "TableQuarter",
  components: { RowFile, TableFiles },
  props: ["reportingFile", "downloadFile"],
};
</script>
