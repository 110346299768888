<template>
  <div v-if="reportingFiles === null">Loading</div>
  <div v-else class="container">
    <div v-for="reportingFile in reportingFiles" :key="reportingFile.name">
      <div v-if="reportingFile.files.length > 0">
        <div class="heading">
          <h1>{{ replaceDashBySpace(reportingFile.name) }}</h1>
          <div class="download-all" @click="downloadAll(reportingFile)">
            Tout télécharger
          </div>
        </div>
        <TableQuarter
          :reportingFile="reportingFile"
          :downloadFile="downloadFile"
        />
      </div>
    </div>
  </div>
</template>
<script>
import HTTP from "@/axios";
import { API_URL } from "@/config/url";
import _ from "lodash";
import TableQuarter from "./TableQuarter";
export default {
  name: "TableAnalyse",
  components: { TableQuarter },
  props: ["reportingFiles"],
  methods: {
    replaceDashBySpace: (value) => value.replace(/-/g, " "),

    downloadFile(folderName, fileName) {
      HTTP.post(`/reporting/files/download/`, {
        folderName,
        fileName,
      }).then((response) => {
        if (response.status === 201) {
          window.open(
            `${API_URL}/reporting/download/${response.data.token}/`,
            "_self"
          );
        }
      });
    },
    downloadAll(reportingFile) {
      _.each(reportingFile.files, (file) => {
        setTimeout(() => {
          this.downloadFile(reportingFile.name, file.name);
        }, 100);
      });
    },
  },
};
</script>
<style scoped>
.container {
  min-height: calc(100vh - 250px);
}

.heading {
  margin: 0 0 8px;
  margin-top: 4px;
  display: flex;
  align-items: center;
}

.heading h1 {
  font-family: "Weissenhof Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 27px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  /* Kermony/Office/Bleu 1 */
  margin: 0px;
  color: #21244e;
}

.heading .download-all {
  cursor: pointer;
  margin-left: auto;
  font-family: "Weissenhof Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;

  /* Kermony/Office/Rose */

  color: #ac2369;
}
</style>
