<template>
  <Content style="padding: 16px">
    <canvas ref="chart"></canvas>
  </Content>
</template>

<script>
import Content from "@/views/App/Transmission/Components/Content";
import _ from "lodash";
import { formatAmount } from "@/utils/number";

const getChartConfig = (data) => {
  // Chart config
  return {
    type: "bar",
    data: data,
    options: {
      font: {
        size: 24,
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || "";
              if (context.parsed.y !== null) {
                label = formatAmount(context.parsed.y, { cents: false });
              }
              return label;
            },
          },
        },
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          display: true,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            font: {
              family: "Weissenhof Grotesk",
              size: 13,
            },
            color: "#B5B5C3",
          },
        },
        y: {
          display: true,
          beginAtZero: true,

          ticks: {
            font: {
              family: "Weissenhof Grotesk",
              size: 10,
            },
            color: "#B5B5C3",
            callback: function (label) {
              return formatAmount(label, { cents: false });
            },
          },
          grid: {
            drawBorder: false,
            lineWidth: 3,
            color: (context) => {
              const zeroLine = context.tick.value;
              const gridColor = zeroLine === 0 ? "#CFD2D4" : "transparent";
              return gridColor;
            },
          },
          //type: "logarithmic",
        },
      },
    },
    defaults: {
      global: {},
    },
  };
};

export default {
  name: "ChartProfitByDate",
  components: { Content },
  props: ["data"],
  mounted() {
    this.drawChart();
  },
  methods: {
    drawChart() {
      const colors = _.map(this.data, () => "#AC2369");

      // Remove years with 0 value.
      const parsedData = _.omitBy(this.data, (n) => n.value === 0);

      const data = {
        labels: _.map(parsedData, ({ name }) => [name]),
        datasets: [
          {
            data: _.map(parsedData, ({ value }) => value),
            backgroundColor: colors,
            borderRadius: 2,
          },
        ],
      };

      // Chart config
      const config = getChartConfig(data);
      const ctx = this.$refs.chart;

      this._drawChart(ctx, config);
    },
    _drawChart(ctx, config) {
      this.chartInstance?.destroy();
      // eslint-disable-next-line no-undef,no-unused-vars
      this.chartInstance = new Chart(ctx, config);
    },
  },
};
</script>

<style scoped></style>
