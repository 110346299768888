<template>
  <div class="container">
    <div style="position: relative">
      <div class="container-chart">
        <div class="legend legend-1">Préservation</div>
        <div class="legend legend-2">Croissance</div>
        <div class="legend legend-3">Rendement</div>
        <div class="legend legend-4">Liquidité</div>

        <canvas ref="chart"></canvas>
      </div>
      <div class="small-legends">
        <div class="item">
          <div class="bloc" style="background-color: #ac2369"></div>
          <Caption style="color: #21244e">Actuel</Caption>
        </div>
        <div class="item">
          <div class="bloc" style="background-color: #71c8da"></div>
          <Caption style="color: #21244e">Cible</Caption>
        </div>
      </div>
    </div>
    <Legends :hideBlock="true" :items="legends"></Legends>
  </div>
</template>

<script>
/*
{
  'Préservation': 'umbrella',
  'Croissance': 'growth',
  'Rendement': 'money',
  'Liquidité': 'umbrella',
}
[
        {
          label: 'Préservation',
          value: '30%',
          icon: { name: 'umbrella', size: 'medium', color: '#919BC0' },
        },
        {
          label: 'Croissance',
          value: '30%',
          icon: { name: 'growth', size: 'thin', color: '#71C8DA' },
        },
        {
          label: 'Rendement',
          value: '20%',
          icon: { name: 'money', size: 'thin', color: '#919BC0' },
        },
        {
          label: 'Liquidité',
          value: '20%',
          icon: { name: 'umbrella', size: 'medium', color: '#71C8DA' },
        },
      ]
 */
import Caption from "@/components/Texts/Caption";
import Legends from "@/views/App/Transmission/Components/Legends";
import _ from "lodash";
const getChartConfig = (data, maxValue) => {
  /*
  v-for="item in items"
      :key="item.label"
      :label="item.label"
      :value="item.value"
      :color="item.color"
      :avatar="item.avatar"
   */
  return {
    type: "radar",
    data: data,
    //borderJoinStyle: "bevel",
    //'round'|'bevel'|'miter'
    fill: false,
    //borderColor: "#CFD2D4",

    options: {
      scales: {
        r: {
          suggestedMin: 0,
          suggestedMax: maxValue,
          angleLines: {
            color: "transparent",
          },
          pointLabels: {
            // display: false,
            color: "transparent",
            font: {
              size: 1,
            },
          },
          ticks: {
            display: false,
          },
        },
      },

      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
      },
    },
  };
};

export default {
  name: "ChartPCRL",
  components: { Legends, Caption },
  props: ["data"],
  data() {
    return {
      legends: [],
    };
  },
  mounted() {
    const totalCurrent =
      this.data.current[0] + this.data.current[1] + this.data.current[2];

    const mappingIcon = {
      Préservation: "umbrella",
      Croissance: "growth",
      Rendement: "money",
      Liquidité: "umbrella",
    };

    // Skip 'Liquidité' from legends array
    this.legends = _.omitBy(this.data.legends, (x) => x === "Liquidité");

    this.legends = _.map(this.legends, (legendName, index) => {
      return {
        label: legendName,
        value: `${((this.data.current[index] / totalCurrent) * 100).toFixed(
          0
        )}%`,
        icon: {
          name: _.get(mappingIcon, legendName, "umbrella"),
          size: "medium",
          color: index % 2 ? "#919BC0" : "#71C8DA",
        },
      };
    });

    this.drawChart();
  },
  methods: {
    drawChart() {
      /*
      const colors = [
        "#AC2369",
        "#B84378",
        "#C35C88",
        "#CD7498",
        "#DA9EB5",
        "#D78CA9",
        "#E0A3B9",
        "#E9B9CA",
        "#F0D2DD",
        "#F8E8ED",
      ];

       */
      //this.legends = toLegends(labels, colors, avatars);

      // Chart data
      //borderColor: '#CFD2D4'

      const data = {
        labels: this.data.legends,
        datasets: [
          {
            label: "Cible",
            backgroundColor: "transparent",
            borderColor: "#AC2369",
            data: this.data.current,
            pointRadius: 1,
          },
          {
            label: "Objectif",
            backgroundColor: "transparent",
            borderColor: "#71C8DA",
            data: this.data.target,
            pointRadius: 1,
          },
        ],
      };

      // Chart config
      const config = getChartConfig(data, 4);
      const ctx = this.$refs.chart;

      this._drawChart(ctx, config);
    },
    _drawChart(ctx, config) {
      this.chartInstance?.destroy();
      // eslint-disable-next-line no-undef,no-unused-vars
      this.chartInstance = new Chart(ctx, config);
    },
  },
};
</script>

<style scoped>
.container-chart {
  position: relative;
  margin: 30px;
  height: fit-content;
}

.legend {
  position: absolute;
  font-family: "Weissenhof Grotesk";
  font-style: normal;
  font-weight: 280;
  font-size: 13px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.4px;

  /* Kermony/Bleu 1 */

  color: #003770;
}

.legend-1 {
  transform: rotate(45deg) translateY(9px) translateX(10px);
  left: 50%;

  /*left: 30%;*/
  /*bottom: 98%;*/
}

.legend-2 {
  transform: rotate(315deg) translateY(26px);
  right: 18px;
  bottom: 50%;
  transform-origin: right;

  /*  right: 0px;*/
  /*bottom: 20px;*/
}

.legend-3 {
  transform: rotate(45deg) translateY(-9px) translateX(-10px);
  right: 50%;
  bottom: 0;
  /*bottom: 20px;*/
  /*right: 140px;*/
}

.legend-4 {
  transform: rotate(315deg) translateY(-4px);
  bottom: 50%;
  transform-origin: left;
}

.small-legends {
  display: flex;
  gap: 8px;
  flex-direction: column;
  position: absolute;
  left: 16px;
  bottom: -2px;
}

.small-legends .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
}
.bloc {
  width: 8px;
  height: 8px;
  border-radius: 1px;
}

.container {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 250px 1fr;
}

/* Mobile only */
@media only screen and (pointer: coarse) and (min-device-width: 320px) and (max-device-width: 480px) {
  .container {
    padding: 0 !important;
    display: block;
  }
}
</style>
